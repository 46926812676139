<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="mb-0">Cashback User</h5>
                <p class="mb-0 text-sm">
                  Daftar user yang menggunakan program cashback ini.
                </p>
              </div>
              <div class="col-md-6 text-end">
                <p>
                  Program Cashback: <b>{{ cashback_program_name }}</b
                  ><br />
                  <small>{{ cashback_program_description }}</small>
                </p>
              </div>
            </div>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="showAddForm"
                  >
                    Tambah Data
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Actions"
                  >
                    <template #default="props">
                      <a v-show="false">
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Hapus data"
                        placement="top-start"
                      >
                        <a
                          href="#"
                          @click.prevent="handleDelete(props.$index, props.row)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic">
    <div class="p-2">
      <h5>Tambahkan User</h5>
      <form @submit.prevent="onSubmit">
        <input v-model="form.id" type="hidden" />
        <input v-model="form.cashback_program_id" type="hidden" />
        <div class="row">
          <div class="col-md-12">
            <label class="form-label">Users</label>
            <select
              v-model="form.user_id"
              class="form-select form-select-lg"
              required
            >
              <option
                v-for="select in selects.users"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>
        

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "CashbackProgramUsers",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Modal,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlselect: "/api/v1/user/select",
      urlread: "/api/v1/cashback_program_users/read",
      urlstore: "/api/v1/cashback_program_users/store",
      urlupdate: "/api/v1/cashback_program_users/update",
      urldelete: "/api/v1/cashback_program_users/delete",
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["program_name", "user_email"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "program_name",
          label: "Program",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "user_email",
          label: "User Email",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "send_package",
          label: "Total Paket",
          minWidth: 100,
          sortable: true,
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      selects: {
        users: [],
      },

      form: {
        id: "",
        users_id: "",
        cashback_program_id: "",
      },
      cashback_program_id: "",
      cashback_program_name: "",
      cashback_program_percentage: "",
      cashback_program_description: "",
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getRouteParam();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    getRouteParam() {
      if (this.$route.params.cashback_program_id == undefined) {
        this.$router.push({ path: "/pages/cashbackprograms" });
      } else {
        this.cashback_program_id = this.$route.params.cashback_program_id;
        this.form.cashback_program_id = this.$route.params.cashback_program_id;
        this.cashback_program_name = this.$route.params.cashback_program_name;
        this.cashback_program_percentage = this.$route.params.cashback_program_percentage;
        this.cashback_program_description = this.$route.params.cashback_program_description;

        this.getTables();
        this.getSelectUsers();
      }
    },
    async getSelectUsers() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlselect, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.users = response.data;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(
          this.urlread + "/" + this.cashback_program_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.datas = response.data;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    showAddForm() {
      this.modals.classic = true;
    },
    async showEditForm(index, row) {
      this.modals.classic = true;
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    refreshTable() {
      this.getTables();
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlstore;
        if (this.form.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdate;
        }
        const response = await axios({
          method: sendmethod,
          url: sendurl,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil diupdate!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Konfirmasi!",
        html:
          `Yakin ingin menghapus data ini!<br /><b>` +
          row.user_email +
          `</b>`,
        showCancelButton: true,
        confirmButtonText: "Yes, hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteRow(row);
        }
      });
    },
    async deleteRow(row) {
      this.loadingService();
      try {
        const response = await axios({
          method: "delete",
          url:
            this.urldelete +
            "/" +
            row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          let indexToDelete = this.tableData.findIndex(
            (tableRow) => tableRow.id === row.id
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          Swal.fire("Good job!", "Data berhasil dihapus!", "success");
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.spanlink:hover {
  cursor: pointer;
}
</style>
